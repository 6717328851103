import React, {FormEvent, useState} from 'react';

import styles from "./Main.module.sass"
import OpenAI from "openai";
import {log} from "node:util";

interface IFormData {
    wantToThank: string,
    thanksYouFor: string,
    whatStyle: string
}

const Main = () => {

        // const onChangeHandler = (event: any) => {
    //     //console.log(event);
    //     setFormData(() => ({
    //         ...formData,
    //             [event.target.name]: event.target.value
    //     }));
    // }

    const [alert, setAlert] = useState<boolean>(false);

    const [message, setMessage] = useState<string | null>(null);

    const [loader, setLoader] = useState<boolean>(false);


    const getMessage = async (data: any) => {
        try{
            const response = await fetch("https://api.recmoney.com/openai.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
                    //"Content-Type": "application/json; charset=utf-8"
                },
                //mode: "no-cors",
                body: JSON.stringify(data)
            });

            const result = await response.text();

            const parseResult = JSON.parse(result);

            //console.log(parseResult.data.choices[0].message.content);

            setMessage(parseResult.data.choices[0].message.content);
        }catch (error){
            console.log(error)
        }finally {
            setLoader(false);
        }
    }

    const onSubmit = (event: any) => {
        event.preventDefault();
        setMessage(null);
        setAlert(false);
        const formData = new FormData(event.currentTarget);

        // @ts-ignore
        const values = [...formData.values()];
        //console.log(values);

        const isEmpty = values.includes("");
        if(isEmpty){
            setAlert(true);
            return;
        }

        const data = Object.fromEntries(formData);

        setLoader(true);

        getMessage(data);

        // {
        //     "error": {
        //     "message": "Rate limit reached for gpt-3.5-turbo-1106 in organization org-yn70xLBt0w6sry3m2D42M0nl on requests per min (RPM): Limit 3, Used 3, Requested 1. Please try again in 20s. Visit https://platform.openai.com/account/rate-limits to learn more. You can increase your rate limit by adding a payment method to your account at https://platform.openai.com/account/billing.",
        //         "type": "requests",
        //         "param": null,
        //         "code": "rate_limit_exceeded"
        // }
        // }

    }



    return (
        <section className={styles.section}>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, aut.</p>

            {alert && (
                <div className={styles.alert}>Пожалуйста заполните все поля!</div>
            )}
            <form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.formInput}>
                    <label>
                        <span>Кому ты хочешь сказать спасибо?</span>
                        {/*<input type="text" name={"for"} onChange={onChangeHandler}/>*/}
                        <input type="text" name={"wantToThank"}/>
                    </label>
                </div>
                <div className={styles.formInput}>
                    <label>
                        <span>За что ты хочешь сказать спасибо?</span>
                        {/*<input type="text" name={"why"} onChange={onChangeHandler}/>*/}
                        <input type="text" name={"thanksYouFor"}/>
                    </label>
                </div>
                <div className={styles.formInput}>
                    <label>
                        <span>В каком стиле ты хочешь сказать спасибо?</span>
                        {/*<input type="text" name={"style"} onChange={onChangeHandler}/>*/}
                        <input type="text" name={"whatStyle"}/>
                    </label>
                </div>

                {loader ? (
                    <div className={styles.loader}></div>
                ) : (
                    <button type={"submit"} className={styles.button}>Отправить</button>
                )}


            </form>

            {message && (
                <div className={styles.answer}>
                    {message}
                </div>
            )}


        </section>
    );
};

export default Main;
